import React from "react";
import { Link } from "gatsby";

import Head from "../../components/head";
import TextWrapAroundImg from "../../components/textWrapAroundImg";
import HeroHeader from "../../components/HeroHeader";
import Headline from "../../components/headline";

import tATHStyles from "./tss-athens.module.css";

import { graphql } from "gatsby";

export const query = graphql`
  query {
    allContentfulTssAthensPage {
      edges {
        node {
          headerImage {
            file {
              url
            }
          }
          headerHeadline {
            headerHeadline
          }
          headerSubheadline {
            headerSubheadline
          }
          section1Headline {
            section1Headline
          }
          section1Image {
            file {
              url
            }
          }
          section1Paragraph {
            section1Paragraph
          }
        }
      }
    }
  }
`;

class TSSAth extends React.Component {
  componentDidMount() {
    Array.from(document.querySelectorAll("p")).forEach(el => {
      let v = el.innerHTML;
      let p1 = v.replace(
        "TSS/ATH",
        "TSS/<span style='font-size:80%;'>ATH</span>"
      );
      el.innerHTML = p1;
    });
  }
  render() {
    return (
      <>
        <Head title="TSS outreach | Athens"></Head>
        <HeroHeader
          location={this.props.location}
          BGImg={
            this.props.data.allContentfulTssAthensPage.edges[0].node.headerImage
              .file.url
          }
          heading={
            this.props.data.allContentfulTssAthensPage.edges[0].node
              .headerHeadline.headerHeadline
          }
          subHeading={
            this.props.data.allContentfulTssAthensPage.edges[0].node
              .headerSubheadline.headerSubheadline
          }
          buttonText="Help Us"
        ></HeroHeader>

        <Headline
          lightBackground={true}
          title={
            this.props.data.allContentfulTssAthensPage.edges[0].node
              .section1Headline.section1Headline
          }
        />
        <TextWrapAroundImg
          imgRight={
            this.props.data.allContentfulTssAthensPage.edges[0].node
              .section1Image.file.url
          }
          paragraphHeader={false}
          lightBackground={true}
        >
          <p style={{ whiteSpace: "pre-wrap" }}>
            {
              this.props.data.allContentfulTssAthensPage.edges[0].node
                .section1Paragraph.section1Paragraph
            }
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              margin: "0",
              padding: "0",
            }}
          >
            <Link
              to="/donate"
              style={{
                textDecoration: "none",
                color: "#ba0c2f",
                fontWeight: "600",
                display: "flex",
                alignItems: "baseline",
              }}
            >
              Help us
            </Link>
          </div>
        </TextWrapAroundImg>
      </>
    );
  }
}
export default TSSAth;
