import React from "react";
import { Link } from "gatsby";

import moreArrow from "../images/moreArrow.png";

import HeroHeaderStyles from "./HeroHeader.module.css";

const heroHeader = props => {
  return (
    <section>
      <div
        className={HeroHeaderStyles.backgroundImageContainer}
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${props.BGImg})`,
        }}
      >
        <div className={HeroHeaderStyles.heroContainer}>
          <h1 className={HeroHeaderStyles.heading}>{props.heading}</h1>
          <p className={HeroHeaderStyles.subHeading}>{props.subHeading}</p>
          <div className={HeroHeaderStyles.buttonContainer}>
            <Link to="/donate" className={HeroHeaderStyles.primaryBtn}>
              {props.buttonText ? props.buttonText : "Donate now"}
            </Link>
          </div>
        </div>
        {props.location.pathname === "/" ? (
          <span className={HeroHeaderStyles.heroMore}>
            more
            <img src={moreArrow}></img>
          </span>
        ) : null}
      </div>
    </section>
  );
};
export default heroHeader;
