import React from "react";

import textWrapAroundImgStyles from "./textWrapAroundimg.module.css";

const textArountImg = props => {
  return (
    <>
      <section
        style={
          props.lightBackground === true
            ? props.noPadding === true
              ? { backgroundColor: "#fff", padding: "0px" }
              : { backgroundColor: "#fff" }
            : props.noPadding === true
            ? { padding: "0px" }
            : null
        }
        className={textWrapAroundImgStyles.ourResearchSection}
      >
        <div>
          {props.paragraphHeader !== false ? (
            props.paragraphHeader !== undefined ? (
              <h2>{props.paragraphHeader}</h2>
            ) : null
          ) : null}
          <div>
            {props.imgRight === false ? null : (
              <div>
                <img src={props.imgRight} />
                <p style={{ paddingBottom: "0" }}>{props.imgText}</p>
              </div>
            )}
            {props.children}
          </div>
        </div>
      </section>
    </>
  );
};
export default textArountImg;
